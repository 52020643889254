@import "../../mixed_spm/styles/homepage";
@import "./layout/homepage/safety-guarantee";

BODY {
	header {
		.background-image-container {
			.background-img.background-img {
				@include media-breakpoint-down(md) {
					left: 50%
				}
			}
		}
	}
}